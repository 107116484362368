import { CSSProperties } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";

export type SpacerProps = {
    type?: "vertical" | "horizontal";
    size: number;
};

export default function Spacer({type = "vertical",size}:SpacerProps) {
    const cssStateManager = useCssStateManager(["spacer"]);
    cssStateManager.useProperty(type==="vertical","type-is-vertical");
    cssStateManager.useProperty(type==="horizontal","type-is-horizontal");
    const styles: CSSProperties = {
        ...((type==="vertical")?{height: `${size}rem`}:{width: `${size}rem`}),
        display: (type==="vertical")?"block":"inline-block"
    };
    return <div style={styles}></div>;
}