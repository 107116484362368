import { useCssStateManager } from "../../../hooks/use_css_state_manager";

export type PageProps = {
    children: any;
    secure?: boolean;
};

export default function Page({children,secure = true}:PageProps) {
    const cssStateManager = useCssStateManager(["page-wrapper"]);
    cssStateManager.useProperty(secure,"secure");
    return (
        <>
            <div className={cssStateManager.getClassNames()}>{children}</div>
            <style jsx>{`
            .page-wrapper {
                /* min-height: 600rem; */
                width: 100%;
                color: #333;
                padding: 50rem 40rem;
                padding-top: 50rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-wrap: wrap;
            }
            .secure {

            }
            `}</style>
        </>
    );
}