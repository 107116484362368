import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../../hooks/use_custom_router";
import { styledButton } from "../button";
import { TextButton } from "../text-button";

export type TextLinkProps = {
    caption: string;
    path: string;
};

export default function TextLink({caption,path}:TextLinkProps) {
    const router = useCustomRouter();

    const handleClick = () => {
        router.push(path);
    };

    return (
        <>
            <div className="text-link">
                <TextButton caption={caption} onClick={handleClick} />
            </div>
            <style jsx>{`
            .text-link {
                display: inline-block;
            }
            `}</style>
        </>
    );
}