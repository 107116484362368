import { useContext } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { AccountSession, AccountUser, consumeAccount, getLastLoginEmail } from "../hooks/use_account";
import { unsecurePage } from "../components/common/unsecure_page";
import Page from "../components/common/form/page";
import Form from "../components/common/form/form";
import Title from "../components/common/form/title";
import Box from "../components/common/form/box";
import P from "../components/common/form/p";
import Input from "../components/common/form/input";
import SubmitButton from "../components/common/form/submit-button";
import { formRequestLogin } from "../lib/shared/form-request-makeup/requests/login";
import TextLink from "../components/common/form/text-link";
import Spacer from "../components/common/spacer";

export type LoginPageProps = {
};

const LoginPage = unsecurePage(function({}:LoginPageProps) {
    const { loginPreloadedEmail,loginPreloadedType,clearLoginPreloaded } = useContext(LayoutStateContext);    

    const account = consumeAccount();

    return (
        <Page>
            <Form 
                requestMakeup={formRequestLogin}
                autoFocus
                align="center"
                input={{
                    maxWidth: 400,
                    captionPosition: "placeholder"
                }}
                onSuccess={({user,session}: {user: AccountUser,session: AccountSession}) => {
                    account.value = {
                        user,
                        session
                    };
                    return {
                        type: "redirect",
                        path: "/account"
                    };
                }}
                initializeCb={() => {
                    const lastLoginEmail = getLastLoginEmail();
                    if (loginPreloadedEmail) {
                        clearLoginPreloaded();
                    }
                    if (loginPreloadedEmail || lastLoginEmail) {
                        return {
                            inputInitialValues: {
                                email: loginPreloadedEmail || lastLoginEmail
                            },
                            autoFocus: "password",
                            ...(loginPreloadedType?{
                                message: {
                                    value: (loginPreloadedType==="activated")?
                                        "Your account has been activated. Enter your password below to login.":
                                        "Your password has been reset. Enter it below to login.",
                                    permanent: true
                                }
                            }:{})
                        };
                    }
                }}
            >
                <Title>Login</Title>
                <Box>
                    <P>
                        Don't have an account yet? <TextLink path="/register" caption="Register" />
                    </P>
                    <Input name="email" />
                    <Input name="password" type="password" />
                    <SubmitButton caption="Login" />
                    <Spacer size={20} />
                    <P>
                        Forgot your password? <TextLink path="/reset-password" caption="Reset" /> it.
                    </P>
                </Box>
            </Form>                    
        </Page>    
    );
});

export default LoginPage;