import { ReactElement } from "react";
import { useSmartState } from "../../hooks/use_smart_state";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { consumeAccount } from "../../hooks/use_account";
import { libAccountTestMode } from "../../lib/account-test-mode";

export function unsecurePage<T>(Component: (props: {}) => ReactElement) {
    return function() {
        const show = useSmartState(false);
        const router = useCustomRouter();
    
        consumeAccount((accountValue) => {
            if (accountValue) {
                router.push("/account");
            }
            else {
                show.value = true;
            }
        });
    
        return show.value?<Component />:<div style={{height: "1000rem"}}></div>;
    };
};