import { FormRequestMakeup } from "../types";

type NameValues = {
    email: string;
    password: string;
};

const _formRequestLogin: FormRequestMakeup<NameValues> = {
    trimValues: ["email"],
    inputs: {
        email: {
            caption: "Email",
            required: true,
            validation: "email"
        },
        password: {
            caption: "Password",
            required: true,
            validation: "min:6"
        }
    },
    relativeUrl: "/users/login?action=login",
};

export const formRequestLogin = _formRequestLogin as FormRequestMakeup;